import { Routes } from '@angular/router';

// Route for content layout with sidebar, navbar and footer.

export const full_routes: Routes = [
  {
    path: 'arquivos',
    loadChildren: './pages/full-pages/full-pages.module#FullPagesModule'
  },
  {
    path: 'processo',
    loadChildren: './pages/full-pages/full-pages.module#FullPagesModule'
  }
];