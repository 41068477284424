import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { seguranca_redirect_url } from '../constantes';
import { UsuarioAutenticado } from '../types/models.type';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private serviceUrl = "api/v1/autenticacao";

  constructor(private cookeiService: CookieService, private http: HttpClient,) { }

  logout() {
    this.cookeiService.removeAll({ domain: 'oab.org.br' });
    window.location.href = seguranca_redirect_url;
  }

  getUsuarioAutenticado(): Observable<UsuarioAutenticado> {
    return this.http.get<UsuarioAutenticado>(this.serviceUrl).pipe(shareReplay(1));
  }
}
