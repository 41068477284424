import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DocumentoService } from 'app/shared/services/documento.service';
import { MinutaService } from 'app/shared/services/minuta.service';
import { ModeloDocumentoService } from 'app/shared/services/modelo-documento.service';
import { TipoFaseService } from 'app/shared/services/tipo-fase.service';
import { AnexoResponse, Minuta, TipoFase } from 'app/shared/types/models.type';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { CkEditorComponent } from './cdeditor/ckeditor.component';

@Component({
  selector: 'app-modal-modelodocumento',
  templateUrl: './modal-modelodocumento.component.html',
  styleUrls: ['./modal-modelodocumento.component.css']
})
export class ModalModelDocumentComponent implements OnInit {

  numeroDocumento: string;
  tituloModal: string;
  showBottonEnviarDocumento;
  showBottonCriarMinuta;

  errorMessage: string;

  loading = false;

  loadingModelos = false;
  loadingGerandoDocumento = false;
  loadingTipoFase = false;

  form: FormGroup;
  formVariaveis: FormGroup;

  modelos$;
  listIdInputs = [];

  anexoResponse: AnexoResponse;
  minutaResponse: any;

  step = 1;

  tiposFase$ = this.tipoFaseService.fetchEntities()
  .pipe(finalize(() => {
    this.loadingTipoFase = false;
  }));

  ckEditor = new CkEditorComponent();
  
  constructor(private bsModalRef: BsModalRef, 
              private fb: FormBuilder,
              private modeloDocumentosService: ModeloDocumentoService,
              private tipoFaseService: TipoFaseService,
              private documentoService: DocumentoService,
              private minutaService: MinutaService) { }

  ngOnInit() {

    this.modelos$ = this.modeloDocumentosService.listarModelosDisponibilizados().pipe(
      finalize(() => {
        this.loadingModelos = false;
      })
      );
      
    this.createForm();
  }

  createForm(){
    this.form = this.fb.group({
      modelo: ['', [ Validators.required ]],
      numerosDocumento: [[this.numeroDocumento]],
      conteudo: [''],
      tipoDocumento: [null],
      descricaoDocumento: ['', [ Validators.required ]],
      tipoFase: [''],
      descricaoFase: [''],
      assinarDocumento: false,
      assinatura: this.fb.group({
        usuario: [''],
        senha: ['']
      })
    });

    this.form.get('assinatura').disable();

    this.form.get('assinarDocumento').valueChanges
    .subscribe(isAssinado => { 
      if(isAssinado) {
        this.form.get('assinatura').enable();
        this.form.get('assinatura.usuario').setValidators(Validators.required);
        this.form.get('assinatura.senha').setValidators(Validators.required);
      } else {
        this.form.get('assinatura').clearValidators();
        this.form.get('assinatura').disable();
      }

      this.form.updateValueAndValidity();
    });
  }

  modalClose() {
    this.bsModalRef.hide();
  }

  voltar() {
    this.form.controls.conteudo.setValue(null);
  }


  gerarDocumento(idModelo){
    this.loadingGerandoDocumento = true;
    let mapVariaveis = new Map<string, string>()  
    for (var value in this.formVariaveis.value) {  
      mapVariaveis.set(value,this.formVariaveis.value[value])  
    } 
    this.modeloDocumentosService.gerarDocumento(idModelo, mapVariaveis).subscribe(documento => {
      this.form.controls.conteudo.setValue(documento.conteudo);
      this.loadingGerandoDocumento = false;
      this.step = 3;
    });
  }
  
  gerarInputVariaveis(idModelo){
    this.formVariaveis = new FormGroup({})
    this.modeloDocumentosService.listarVariaveisDasTAGsDoModelo(idModelo).subscribe(variaveis => 
      {
        this.formVariaveis.addControl("numero_documento", new FormControl(this.numeroDocumento, Validators.required));
        
        variaveis.forEach(variavel=>{
          this.formVariaveis.addControl(variavel, new FormControl('', Validators.required));
        });
        
        if(variaveis.length == 0){
          this.gerarDocumento(idModelo);
        }else {
          this.listIdInputs = variaveis;
          this.step = 2;
        }
      }
    );
  }

  onTipoFaseChanged(tipoFase: TipoFase) {
    const descricaoFaseControl = this.form.get('descricaoFase');
   
    if(tipoFase) {
      descricaoFaseControl.setValidators([Validators.required, Validators.maxLength(6000)]);
    } else {
      descricaoFaseControl.clearValidators();
    }

    descricaoFaseControl.updateValueAndValidity();
  }

  onModeloChanged(modelo){
    this.form.controls.tipoDocumento.setValue(modelo.tipoDocumento);
  }


  submit() {
    this.loading = true;

    this.form.get('assinatura.usuario').markAsDirty();
    this.form.get('assinatura.senha').markAsDirty();
    this.form.get('assinatura').updateValueAndValidity();

    for(const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    if(this.form.valid) {
      this.modeloDocumentosService.gerarPDF(this.form.controls.modelo.value, this.form.controls.conteudo.value).subscribe(anexo => {
        this.enviarAnexo(anexo)
      });
    }
  }

  enviarMinuta(){
    this.loading = true;

    this.form.get('assinatura.usuario').markAsDirty();
    this.form.get('assinatura.senha').markAsDirty();
    this.form.get('assinatura').updateValueAndValidity();

    for(const i in this.form.controls) {
      this.form.controls[i].markAsDirty();
      this.form.controls[i].updateValueAndValidity();
    }

    let minuta = new Minuta();
    minuta.conteudo = this.form.controls.conteudo.value;
    minuta.idModelo =  this.form.get('modelo').value;
    minuta.tipoDocumento =  this.form.get('tipoDocumento').value;
    minuta.descricaoDocumento = this.form.get('descricaoDocumento').value;
    minuta.tipoFase = this.form.get('tipoFase').value;
    minuta.descricaoFase = this.form.get('descricaoFase').value;

    if(this.form.valid) {
      this.minutaService.criar(this.numeroDocumento, minuta).pipe(
        tap(minuta => this.minutaResponse = minuta),
        catchError(err => {
          this.errorMessage = err.error.message;
          return throwError(err);
        }),
        finalize(() => this.loading = false)
      ).subscribe(() => {
        this.bsModalRef.hide();
      });
    }
  }

  enviarAnexo(anexo: Blob) {
   
    this.loading = true;
    this.errorMessage = null;

    const formData = new FormData();
    formData.append('numerosDocumento', this.numeroDocumento);
    formData.append('tipoDocumento', this.form.get('tipoDocumento').value);
    formData.append('tipoFase', this.form.get('tipoFase').value);
    formData.append('descricaoDocumento', this.form.get('descricaoDocumento').value);
    formData.append('descricaoFase', this.form.get('descricaoFase').value);

    let arrayOfBlob = new Array<Blob>();
    arrayOfBlob.push(anexo);

    formData.append('arquivo', new File(arrayOfBlob, "conteudo.pdf", { type: 'application/pdf' }));

    const assinarDocumento = this.form.get('assinarDocumento').value;
    
    if(assinarDocumento) {
      formData.append('assinatura.usuario', this.form.get('assinatura.usuario').value);
      formData.append('assinatura.senha', this.form.get('assinatura.senha').value);
    }

    this.documentoService.upload(formData)
    .pipe(
      tap(anexo => this.anexoResponse = anexo),
      catchError(err => {
        this.errorMessage = err.error.message;
        return throwError(err);
      }),
      finalize(() => this.loading = false)
    ).subscribe(() => {
      this.bsModalRef.hide();
    });
  }

  downloadPrevia(){
    this.modeloDocumentosService.downloadPreviaPDF(this.form.controls.modelo.value, this.form.controls.conteudo.value).subscribe();
  }

  escolherNovoModelo(){
    this.step = 1;
  }
}