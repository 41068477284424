import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TipoDocumentoDigital } from "../types/models.type";
import { shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class TipoDocumentoDigitalService {

    private serviceUrl = 'api/v1/tipo-documento-digital';

    constructor(
        private http: HttpClient
    ) {}

    fetchEntities(): Observable<TipoDocumentoDigital[]> {
        return this.http.get<TipoDocumentoDigital[]>(`${this.serviceUrl}`)
        .pipe(shareReplay(1));
    }

}