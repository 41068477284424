import { Component, Input, ViewEncapsulation } from '@angular/core';
import ClassicEditor from '../../../../assets/modules/ckeditor/ckeditor.js';

export class CkEditorComponent {

  public editor = ClassicEditor;
 
  public editorConfig = {
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        'underline',
        'fontSize',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'alignment',
        '|',
        'link',
        'imageUpload',
        'blockQuote',
        'insertTable',
        'undo',
        'redo'
      ]
    },
    language: 'pt-br',
    image: {
      toolbar: [
        'imageTextAlternative',
        'imageStyle:inline',
        'imageStyle:block',
        'imageStyle:side'
      ]
    },
    table: {
      contentToolbar: [
        'tableColumn',
        'tableRow',
        'mergeTableCells'
      ]
    }
  }
}