import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { TipoFase } from "../types/models.type";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class TipoFaseService {

    private serviceUrl = "api/v1/tipo-fase";

    constructor(
        private http: HttpClient
    ) {}

    fetchEntities(): Observable<TipoFase[]> {
        return this.http.get<TipoFase[]>(`${this.serviceUrl}`)
        .pipe(shareReplay(1));
    }

}