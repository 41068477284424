import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AnexoResponse, Assinatura, SignRequest, Documento, EstruturaDocumento } from "../types/models.type";
import { map, tap, take } from "rxjs/operators";
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: "root"
})
export class ModeloDocumentoService {

    private apiUrl = "modelo-documentos/v1/integracao/modelo";

    constructor(
        private http: HttpClient
    ) {}

    listarVariaveisDasTAGsDoModelo(idModelo): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/${idModelo}/variaveis`)
        .pipe(take(1));
    }

    listarModelosDisponibilizados(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/disponibilizados`)
        .pipe(take(1));
    }

    gerarDocumento(idModelo, mapVariaveis?: Map<string, string>): Observable<any> {
        let variaveis = {};
    
        if(mapVariaveis){
          mapVariaveis.forEach((val: string, key: string) => {
            variaveis[key] = val;
          });
        }
    
        return this.http.post<any>(`${this.apiUrl}/${idModelo}/gerar-documento`, variaveis)
        .pipe(take(1));
      }

      downloadPreviaPDF(idModelo, conteudoHtml){
        return this.http.post<Blob>(`${this.apiUrl}/${idModelo}/download/pdf`, conteudoHtml, {responseType: 'blob' as 'json' })
        .pipe(
            take(1),
            map(res => new Blob([res], { type: "application/pdf" })),
            tap(res => saveAs(res, `documento.pdf`))
        );
      }

      gerarPDF(idModelo, conteudoHtml) : Observable<Blob> {
        return this.http.post<Blob>(`${this.apiUrl}/${idModelo}/download/pdf`, conteudoHtml, {responseType: 'blob' as 'json' })
        .pipe(
            take(1),
            map(res => new Blob([res], { type: "application/pdf" }))
        );
      }
}