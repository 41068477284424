import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProtocoloService } from 'app/shared/services/protocolo.service';
import { ParteService } from 'app/shared/services/parte.service';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { ProtocoloInfo } from 'app/shared/types/protocolo-info';
import { ParteProcesso } from 'app/shared/types/parte-processo';
import { map, shareReplay } from 'rxjs/operators';
import { AuditoriaService } from 'app/shared/services/auditoria.service';
import { IPagination, Pagination } from 'app/shared/types/pagination.type';
import { ListComponent } from 'app/shared/types/list.component';

@Component({
  selector: 'app-modal-historico-acesso',
  templateUrl: './modal-historico-acesso.component.html',
  styleUrls: ['./modal-historico-acesso.component.css']
})
export class ModalHistoricoAcessoComponent extends ListComponent<any>  implements OnInit{

  @Input() numeroDocumento: string;

  private pageChangeSubject = new BehaviorSubject<IPagination>(new Pagination());

  auditorias: Array<any>;

  constructor(
    private bsModalRef: BsModalRef,
    private auditoriaService: AuditoriaService
  ) {

    super()
  }


  handleClose() {
    this.bsModalRef.hide();
  }

  ngOnInit() {
    this.auditoriaService.consultaPaginadaPath(this.pageChangeSubject.asObservable(), of({}), this.numeroDocumento).subscribe(auditoriasPaginadas => {
      this.auditorias = auditoriasPaginadas.content
      this.totalElements = auditoriasPaginadas.totalElements

    });
  }

  onPageChange($event){
     this.pageChangeSubject.next({pageSize: this.pageSize, pageIndex: $event.page});
  }


}
