import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ParteProcesso } from '../types/parte-processo';

@Injectable({
  providedIn: 'root'
})
export class ParteService {

  private serviceUrl = 'api/v1/parte-processo';

  constructor(private http: HttpClient) { }

  listar(numeroDocumento: string): Observable<ParteProcesso[]> {
    return this.http.get<ParteProcesso[]>(`${this.serviceUrl}/${numeroDocumento}`);
  }

}
