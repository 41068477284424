import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Page } from "../types/page.type";


@Injectable({
    providedIn: "root"
})
export class MembroService {

    private serviceUrl = "api/v1/membro";

    constructor(private http: HttpClient) {}

    consultar(search?: { [key: string]: string }): Observable<Page<any[]>> {
        const params = new HttpParams({ fromObject: search });
        return this.http.get<Page<any[]>>(`${this.serviceUrl}/consulta`, { params });
    }
}