import { Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-content-layout',
    templateUrl: './content-layout.component.html',
    styleUrls: ['./content-layout.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ContentLayoutComponent {

}