import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID } from '@angular/core';
import { registerLocaleData, CommonModule } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ModalModule } from 'ngx-bootstrap/modal';
import { environment } from 'environments/environment';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
import { ContentLayoutComponent } from './layouts/content/content-layout.component';
import { full_routes } from './shared/routes/full-layout.routes';
import { content_routes } from './shared/routes/content-layout.routes';
import { EventEmitterService } from './shared/services/event-emitter.service';
import { KeysPipe } from './shared/pipes/iterate-keys.pipe';
import { ModalAssinaturaComponent } from './components/modal-assinatura/modal-assinatura.component';
import { ModalUploadComponent } from './components/modal-upload/modal-upload.component';

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { CookieModule } from 'ngx-cookie';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { ModalInfoComponent } from './components/modal-info/modal-info.component';
import { ModalAcessoNegadoComponent } from './components/modal-acesso-negado/modal-acesso-negado.component';
import { HttpConfigInterceptor } from './shared/interceptors/http-config.interceptor';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { TimeAgoPipe } from './components/timeAgoPipe/time-ago.pipe';
import { ModalNotificacaoComponent } from './components/modal-notificacao/modal-notificacao.component';
import { ModalModelDocumentComponent } from './components/modal-modelodocumento/modal-modelodocumento.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ModalMembrosMinutaComponent } from './components/modal-membrosminuta/modal-membrosminuta.component';
import { ModalPreAssinaturaComponent } from './components/modal-preassinatura/modal-preassinatura.component';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalHistoricoAcessoComponent } from './components/modal-historico-acesso/modal-historico-acesso.component';


registerLocaleData(localePt, 'pt-BR');

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/pages/404',
    pathMatch: 'full'
  },
  { 
    path: '', component: FullLayoutComponent,
    children: full_routes
  },
  { 
    path: '', component: ContentLayoutComponent,
    children: content_routes
  },
  { path: '**', redirectTo: '/pages/404' }
];

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        FullLayoutComponent,
        ContentLayoutComponent,
        KeysPipe,
        ModalAssinaturaComponent,
        ModalPreAssinaturaComponent,
        ModalUploadComponent,
        ModalInfoComponent,
        ModalAcessoNegadoComponent,
        TopBarComponent,
        TimeAgoPipe,
        ModalNotificacaoComponent,
        ModalModelDocumentComponent,
        ModalMembrosMinutaComponent,
        ModalHistoricoAcessoComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        LoadingBarModule,
        HttpClientModule,
        NgSelectModule,
        ModalModule.forRoot(),
        CookieModule.forRoot(),
        RouterModule.forRoot(routes, { useHash: false }),
        LoggerModule.forRoot({
          level: !environment.production ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.OFF,
          serverLogLevel: NgxLoggerLevel.OFF
        }),
        CKEditorModule,
        PaginationModule.forRoot(),
    ],
    providers: [
      EventEmitterService,
      { provide: LOCALE_ID, useValue: 'pt-BR' },
      { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true  },    
      { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true  },    
    ],
    bootstrap: [ AppComponent ],
    entryComponents: [ 
      ModalAssinaturaComponent,
      ModalPreAssinaturaComponent,
      ModalUploadComponent,
      ModalInfoComponent,
      ModalAcessoNegadoComponent,
      ModalNotificacaoComponent,
      ModalModelDocumentComponent,
      ModalMembrosMinutaComponent,
      ModalHistoricoAcessoComponent
    ]
})

export class AppModule {

}
