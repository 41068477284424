import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Page } from '../types/page.type';
import { IPagination } from '../types/pagination.type';
import { PAGINACAO_PAGE_SIZE } from '../constantes';

@Injectable({
  providedIn: 'root'
})
export class AuditoriaService {

  private apiUrl = `api/v1/auditoria`;

  constructor(private http: HttpClient) { }

  // getAuditorias(numeroDocumento: string): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/${numeroDocumento}`)
  //   .pipe(
  //     take(1)
  //   );
  // }


// consultaPaginada(pageChange:Observable<IPagination>, search?: Observable<{ [key: string]: string }>): Observable<Page<any[]>> {
//     return this.consultaPaginadaPath(pageChange, search, 'consulta');
// }

  consultaPaginadaPath(pageChange:Observable<IPagination>, search: Observable<{ [key: string]: string }>, path: String): Observable<Page<any[]>> {
      return combineLatest([
          pageChange, search
          ])
      .pipe(
        map(([pageChange, search]) => {    
          let param = search;
              param['page'] = pageChange.pageIndex? `${pageChange.pageIndex-1}` : `${-1}`;
              param['size'] = pageChange.pageSize? `${pageChange.pageSize}` : `${PAGINACAO_PAGE_SIZE}`;
              return param;
            }),
        switchMap(param => this.consultar(param, path)));
  }

  consultar(search: { [key: string]: string }, path: String): Observable<Page<any[]>> {
      const params = new HttpParams({ fromObject: search });
      return this.http.get<Page<any[]>>(`${this.apiUrl}/${path}`, { params });
  }

}
