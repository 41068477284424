import { EventEmitter, Input, Output } from "@angular/core";
import { PAGINACAO_PAGE_SIZE } from "../constantes";
import { IPagination } from "./pagination.type";





export abstract class ListComponent<E> {

    @Input() list:E[] = [];
    @Input() fetching: boolean = true;
    @Input() totalElements: number = 0;

    @Output() pageChangeEvent = new EventEmitter<IPagination>();

    pageIndex: number = 1;
    pageSize: number = PAGINACAO_PAGE_SIZE;

    constructor() {}

    onPageIndexChanged(index: number) {
        this.pageIndex = index;
        this.pageChangeEvent.emit({pageSize: this.pageSize, pageIndex: this.pageIndex});
    }
    
    onPageSizeChanged(size: number) {
        this.pageSize = size;
        this.pageChangeEvent.emit({pageSize: this.pageSize, pageIndex: this.pageIndex});
    }

}
