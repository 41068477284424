import { EventEmitter } from '@angular/core';

export class EventEmitterService {

    private static emitters: {[nomeEvento: string]: EventEmitter<any>} = {}

    static get<T> (nomeEvento:string): EventEmitter<T> {
        if (!this.emitters[nomeEvento])
            this.emitters[nomeEvento] = new EventEmitter<T>();
        return this.emitters[nomeEvento];
    }

}