import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ProcessoRelator } from "../types/models.type";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class ProcessoRelatorService {

    private serviceUrl = 'api/v1/processo-relator';

    constructor(
        private http: HttpClient
    ) {}

    fetchEntities(numeroDocumento: string): Observable<ProcessoRelator[]> {
        return this.http.get<ProcessoRelator[]>(`${this.serviceUrl}/${numeroDocumento}`);
    }

}