import { Component, OnInit, OnDestroy } from '@angular/core';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { event_documento_carregado } from 'app/shared/constantes';
import { AgrupadorDocumento, ProcessoRelator } from 'app/shared/types/models.type';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { takeWhile, tap, switchMap, finalize } from 'rxjs/operators';
import { ProcessoRelatorService } from 'app/shared/services/processo-relator.service';
import { CertificadoDigitalService } from 'app/shared/services/certificado-digital.service';
import { DocumentoService } from 'app/shared/services/documento.service';
import { ModalUploadComponent } from '../modal-upload/modal-upload.component';
import { AuthService } from 'app/shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
})
export class TopBarComponent implements OnInit, OnDestroy {

  private componentActive = true;

  assinaturas: any[];
  assinatura: any = {};
  assinarUsuarioSenha: boolean = false;

  anexo: any = {
    tipoDocumento: '',
    tipoFase: '',
    descricaoDocumento: '',
    descricaoFase: '',
    arquivo: '',
    file: '',
    notificarRelator: false,
    assinarDocumento: false
  }

  certificado: any = {
    nome: '',
    arquivo: '',
    file: ''
  }

  documento: AgrupadorDocumento
  modalUploadRef: BsModalRef

  listProcessoRelator: Array<ProcessoRelator>

  showButtonDownload = true;

  showButtonUpload = false;

  showButtonModelDocument = false;
  showButtonAccessHistory = false;

  constructor(
    private modalService: BsModalService,
    private processoRelatorService: ProcessoRelatorService,
    private certificadoDigitalService: CertificadoDigitalService,
    private documentoService: DocumentoService,
    private authService: AuthService,
    private router: Router,
  ) {}

  ngOnInit() {

    EventEmitterService.get<AgrupadorDocumento>(event_documento_carregado)
    .pipe(
      takeWhile(() => this.componentActive),
      tap(doc => this.documento = doc),
      switchMap(doc => this.processoRelatorService.fetchEntities(doc.numeroDocumento)
      .pipe(
        tap(relatores => this.listProcessoRelator = relatores)
      ))
    ).subscribe();

    this.showButtonModelDocument = this.showButtonAccessHistory = this.showButtonUpload = this.router.url.match('/processo/') == null;
  }

  openModal() {
    EventEmitterService.get("event_enviar_peca").next();
    // const initialState = {
    //   numeroDocumento: this.documento.numeroDocumento
    // };

    // this.modalService.show(
    //   ModalUploadComponent, 
    //   { initialState }
    // );
  }

  openModalCriarDocumento(){
    EventEmitterService.get("event_criar_documento").next();
  }

  openModalCriarMinuta(){
    EventEmitterService.get("event_criar_minuta").next();
  }

  openAccessHistory(){
    EventEmitterService.get("event_access_history").next();
  }

  ngOnDestroy() {
    this.componentActive = false;
  }

  enviarCertificado() {
    const formData = new FormData()
    formData.append('descricao', this.certificado.nome)
    formData.append('file', this.certificado.file)

    this.certificadoDigitalService.upload(formData)
    .pipe(
      finalize(() => this.modalUploadRef.hide())
    ).subscribe();
  }

  downloadAndSave() {    
    EventEmitterService.get("event_baixar_processo").next();

    // this.documentoService.download(this.documento.numeroDocumento)
    // .subscribe();
  }

  logout() {
    this.authService.logout();
  }

}
