import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { AnexoResponse, Assinatura, SignRequest, Documento, EstruturaDocumento, Minuta } from "../types/models.type";
import { map, tap, take } from "rxjs/operators";
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: "root"
})
export class DocumentoService {

    private serviceUrl = "api/v1/documento";

    constructor(
        private http: HttpClient
    ) {}

    fetchEntity(numeroDocumento: string, search: string = ''): Observable<Documento[]> {
        const params = new HttpParams().set('search', search);
        return this.http.get<Documento[]>(`${this.serviceUrl}/${numeroDocumento}`, { params })
        .pipe(take(1));
    }

    sign(signRequest: SignRequest): Observable<Assinatura> {
        return this.http.post<Assinatura>(`${this.serviceUrl}/assinar`, signRequest)
        .pipe(take(1));
    }

    upload(formData: FormData): Observable<AnexoResponse> {
        return this.http.post<AnexoResponse[]>(`${this.serviceUrl}/uploadInterno`, formData)
        .pipe(
            take(1),
            map(anexos => anexos[0])
        );
    }

    download(numeroDocumento: string) {
        return this.http.get<Blob>(
            `${this.serviceUrl}/download/${numeroDocumento}`, 
            { responseType: 'blob' as 'json' }
        ).pipe(
            take(1),
            map(res => new Blob([res], { type: "application/pdf" })),
            tap(res => saveAs(res, `${numeroDocumento}.pdf`))
        );
    }

    montarEstruturaDocumento(numeroDocumento: string, search: string = ''): Observable<EstruturaDocumento> {
        const params = new HttpParams().set('search', search);
        return this.http.get<EstruturaDocumento>(`${this.serviceUrl}/${numeroDocumento}/estrutura`, { params })
        .pipe(take(1));
    }
}