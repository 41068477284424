import { Identifiers } from "@angular/compiler";
import { SafeUrl } from "@angular/platform-browser";

export interface AgrupadorDocumento {
    numeroDocumento: string;
    assunto: string;
    totalPaginas?: number;
    documentos?: Array<Documento>;
}

export interface EstruturaDocumento {
    id: Identifiers;
    numeroDocumento: string;
    totalPaginas?: number;
    pecas?: Array<Documento>;
    anexos?: Array<Documento>;
    minutas?: Array<Minuta>;
}

export interface Documento {
    id: number;
    nome: string;
    nomeTipoDocumento: string;
    tipoProtocolo: string;
    descricao: string;
    numeroDocumentoVinculado: string;
    nomeFuncionario: string;
    nomeSetor: string;
    paginas: number;
    paginaInicial?: number;
    paginaFinal?: number;
    dataInclusao?: Date;
    cacheUrl?: string;
    downloadUrl?: SafeUrl
    assinaturas?: Assinatura[]
    anexo?: boolean;
    extensao?: string;
	texto?: string; 
    codigoExterno?: string;
	ordem?: number;
}

export interface TipoDocumentoDigital {
    id: string;
    descricao: string;
}

export interface TipoFase {
    id: string;
    descricao: string;
}

export interface ProcessoRelator {
    id: number;
    protocolo: string;
    nome: string;
    codigoOrganizacao: number;
    codigoSetor: number;
    codigoMembro: number;
    codigoTipoDistribuicao: number;
}

export interface AnexoResponse {
    uuid: string;
    nome: string;
    status?: string;
    paginas?: number;
    anexo?: boolean;
    id?: number;
    numeroDocumento: string | null;
    nomeFuncionario?: string;
    nomeSetor?: string;
    extensao?: string;
    dataInclusao?: Date;
    codigoExterno?: string;
    assinatura?: Assinatura;
}

export interface CertificadoDigital {
    id: string;
    nome: string;
}

export interface Assinatura {
    codigoArquivo: number;
    dataAssinatura: Date;
    nome: string;
}

export interface SignRequest {
    codigoCertificado?: number;
    codigoArquivo: number;
    usuario: string;
    senha: string;
}

export interface Alert {
    type: string;
    message: string;
}

export class Minuta {
    id: number;
    tipoDocumento: string;
    conteudo: string;
    idenProt: number;
    descricaoDocumento: string;
    tipoFase?: number;
    descricaoFase?: string;
    nomeTipoDocumento?: string;
    assinatura?: Assinatura;
    assinaturas?: Assinatura[]
    nomeFuncionario?: string;
    nomeSetor?: string;
    codigoMembroCadastro?: number;
    dataInclusao?: Date;
    dataAtualizacao?: Date;
    idModelo: number
}

export interface UsuarioAutenticado {
    login: string;
    email: string;
    nome: string;
    nomeSetor?: string;
    nomeOrganizacao?: string;
    codigoMembro: number;
    codigoOrganizacao: number;
    codigoSetor: number;
    enabled?: boolean;
    accountNonExpired?: boolean;
    accountNonLocked?: boolean;
    credentialsNonExpired?: boolean;
    organizacaoUtilizaSGD: boolean;
    authorities?: any[];
}