import { Component, Input } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';

@Component({
  selector: 'app-modal-acesso-negado',
  templateUrl: './modal-acesso-negado.component.html',
  styleUrls: ['./modal-acesso-negado.component.css']
})
export class ModalAcessoNegadoComponent {

  @Input() errorMessage: string;

  constructor(private authService: AuthService) { }

  logout() {
    this.authService.logout();
  }

}
