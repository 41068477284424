import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProtocoloInfo } from '../types/protocolo-info';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProtocoloService {

  private serviceUrl = `api/v1/protocolo`;

  constructor(private http: HttpClient) { }

  getInfoProtocolo(numeroDocumento: string): Observable<ProtocoloInfo> {
    return this.http.get<ProtocoloInfo>(`${this.serviceUrl}/${numeroDocumento}/info`)
    .pipe(
      take(1)
    );
  }

}
