import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, debounceTime, distinctUntilChanged, filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { MinutaService } from 'app/shared/services/minuta.service';
import { MembroService } from 'app/shared/services/membro.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { concat, Observable, of, Subject } from 'rxjs';
import { Page } from 'app/shared/types/page.type';
import { UsuarioAutenticado } from 'app/shared/types/models.type';

@Component({
  selector: 'app-modal-membrosminuta',
  templateUrl: './modal-membrosminuta.component.html',
  styleUrls: ['./modal-membrosminuta.component.css']
})
export class ModalMembrosMinutaComponent implements OnInit {

  @Input() numeroDocumento: string;
  @Input() idMinuta: number;
  @Input() usuarioAutenticado: UsuarioAutenticado;
  @Input() showSearch = true;

  membros$: Observable<any[]>;

 // listMembrosAutoComplete$: Observable<Page<any[]>>;
  listMembrosAutoComplete$: Observable<any>;
  form: FormGroup;

  searchNomeParticipante$ = new Subject<string>();

  isConfirmLoading = false;

  loadingSearch = false;

  responseMembrosAtualizados = false;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    protected membroService: MembroService,
    private minutaService: MinutaService,
  ) {}

  ngOnInit() {

    // this.listMembrosAutoComplete$ = this.membroService.consultar({nome:''}).pipe(
    //   debounceTime(500), 
    //   distinctUntilChanged(),
    //   finalize(() => this.loadingSearch = false)
    // );

    this.membros$ = this.minutaService.listarMembros(this.numeroDocumento, this.idMinuta);
    this.defineValueChangeSearch();
    this.createForm();
  }

  createForm(){
    this.form = this.fb.group({
      nomeParticipante: ['',  Validators.required],
    });
  }

  // search = (term: string, item: any) => {
  //   this.listMembrosAutoComplete$ = this.membroService.consultar({nome:term}).pipe(
  //       debounceTime(500), 
  //       distinctUntilChanged(),
  //       finalize(() => this.loadingSearch = false)
  //     );

  // }

  defineValueChangeSearch(){
    let minLengthTerm = 3;
    this.listMembrosAutoComplete$ = concat(

      this.searchNomeParticipante$.pipe(
        filter(res => {
          return res !== null && res.length >= minLengthTerm;
        }),
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.loadingSearch = true),
        switchMap(term => {

          return this.membroService.consultar({nome:term}).pipe(
            catchError(() => of(null)), // empty list on error
            tap(() => this.loadingSearch = false),
            map((list:any) =>
              list.content.map((m) => m.possuiUsuarioSistema ? m : { ...m, disabled: true })
            )
          )
        })
      )
    );
  }

  
  addMembro(membro){

    this.minutaService.vincularMembro(this.numeroDocumento, this.idMinuta, membro.id).subscribe(()=> {
        this.responseMembrosAtualizados = true;
        this.ngOnInit()
      }
    );
  }


  handleClose() {
    this.bsModalRef.hide();
  }

  desvincularMembro(idMembro){ 
    this.minutaService.desvincularMembro(this.numeroDocumento, this.idMinuta, idMembro).subscribe(()=> {
      this.responseMembrosAtualizados = true;
      this.ngOnInit()
    });
  }
}

