import { environment } from "environments/environment";

//export const api_documento = 'api/recursos/documento';
/* export const api_assinatura = 'api/recursos/documento/assinar';
export const api_certificado = 'api/recursos/certificado-digital/';
export const api_certificado_upload = 'api/recursos/certificado-digital/upload';
export const api_arquivo = 'api/recursos/documento/arquivo';
export const api_upload = 'api/recursos/documento/upload';
export const api_tipo_documento_digital = 'api/recursos/tipo-documento-digital/';
export const api_tipo_fase = 'api/recursos/tipo-fase/';
export const api_processo_relator = 'api/recursos/processo-relator'; */

export const event_documento_carregado = 'event_documento_carregado';
export const event_upload = 'event_upload_iniciado';

export const event_download_processo = 'event_download_processo';
export const event_assinar_documento = 'event_assinar_documento';
export const event_documento_assinado = 'event_documento_assinado';
export const header_message = 'x-app-message';
export const seguranca_redirect_url = `https://${environment.production ? 'www1' : 'homnet'}.oab.org.br/Login/?aplicacao=59&ReturnUrl=${window.location.href}`;

export const upload_status_success = 'success';
export const upload_status_error = 'error';
//export const upload_status_pushing = 'pushing';

export const PAGINACAO_PAGE_SIZE = 10;