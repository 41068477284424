import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentoService } from 'app/shared/services/documento.service';
import { TipoDocumentoDigitalService } from 'app/shared/services/tipo-documento-digital.service';
import { TipoFaseService } from 'app/shared/services/tipo-fase.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AnexoResponse, TipoFase } from 'app/shared/types/models.type';

const files = [
  {type:'application/pdf', maxSize:31457280},
  {type:'audio/mp3', maxSize:104857600},
  {type:'audio/mpeg', maxSize:104857600},
  {type:'video/mp4', maxSize:104857600}
];

function validadeFileSize(files: {type,maxSize}[]) {
  return (a: AbstractControl): { [key: string]: boolean } | null => {
    const file = a.value as File;
    if(files.find(fileDefinition => fileDefinition.type === file.type && file.size > fileDefinition.maxSize)) {
      return { 'size': true };
    }

    return null;
  };
}

function validadeFileType(files: {type}[]) {
  return (a: AbstractControl): { [key: string]: boolean } | null => {
    const file = a.value as File;
    if(!(files.find(fileDefinition => fileDefinition.type === file.type))) 
    {
      return { 'type': true };
    }

    return null;
  };
}

@Component({
  selector: 'app-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.css']
})
export class ModalUploadComponent implements OnInit {

  numeroDocumento: string;

  errorMessage: string;

  loading = false;

  loadingTipoDocumento = true;

  loadingTipoFase = true;

  uploadForm: FormGroup;

  anexoResponse: AnexoResponse;

  sortConfig = {
    direction: 'asc',
    field: 'descricao'
  };

  tiposDocumento$ = this.tipoDocumentoService.fetchEntities()
  .pipe(finalize(() => {
    this.loadingTipoDocumento = false;
  }));

  tiposFase$ = this.tipoFaseService.fetchEntities()
  .pipe(finalize(() => {
    this.loadingTipoFase = false;
  }));

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private documentoService: DocumentoService,
    private tipoDocumentoService: TipoDocumentoDigitalService,
    private tipoFaseService: TipoFaseService
  ) { }

  ngOnInit() {

    this.uploadForm = this.fb.group({
      'tipoDocumento': ['', [ Validators.required ]],
      'tipoFase': [''],
      'descricaoFase': [''],
      'descricaoDocumento': ['', [ Validators.required ]],
      'arquivo': ['', [ Validators.required, validadeFileSize(files), validadeFileType(files) ]],
      'assinarDocumento': false,
      'assinaturaGroup': this.fb.group({
        'usuario': [''],
        'senha': ['']
      })
    });

    this.uploadForm.get('assinarDocumento').valueChanges
    .subscribe(isAssinado => { 
      if(isAssinado) {
        this.uploadForm.get('assinaturaGroup.usuario').setValidators(Validators.required);
        this.uploadForm.get('assinaturaGroup.senha').setValidators(Validators.required);
      } else {
        this.uploadForm.get('assinaturaGroup').clearValidators();
      }

      this.uploadForm.updateValueAndValidity();
    });
  }

  submit() {
    this.uploadForm.get('assinaturaGroup.usuario').markAsDirty();
    this.uploadForm.get('assinaturaGroup.senha').markAsDirty();
    this.uploadForm.get('assinaturaGroup').updateValueAndValidity();

    for(const i in this.uploadForm.controls) {
      this.uploadForm.controls[i].markAsDirty();
      this.uploadForm.controls[i].updateValueAndValidity();
    }

    if(this.uploadForm.valid) {
      this.enviarAnexo();
    }
  }

  enviarAnexo() {
    this.loading = true;
    this.errorMessage = null;

    const formData = new FormData();
    formData.append('numerosDocumento', this.numeroDocumento);
    formData.append('tipoDocumento', this.uploadForm.get('tipoDocumento').value);
    formData.append('tipoFase', this.uploadForm.get('tipoFase').value);
    formData.append('descricaoDocumento', this.uploadForm.get('descricaoDocumento').value);
    formData.append('descricaoFase', this.uploadForm.get('descricaoFase').value);
    formData.append('arquivo', this.uploadForm.get('arquivo').value);

    const assinarDocumento = this.uploadForm.get('assinarDocumento').value;
    if(assinarDocumento) {
      formData.append('assinatura.usuario', this.uploadForm.get('assinaturaGroup.usuario').value);
      formData.append('assinatura.senha', this.uploadForm.get('assinaturaGroup.senha').value);
    }

    this.documentoService.upload(formData)
    .pipe(
      tap(anexo => this.anexoResponse = anexo),
      catchError(err => {
        this.errorMessage = err.error.message;
        return throwError(err);
      }),
      finalize(() => this.loading = false)
    ).subscribe(() => {
      this.bsModalRef.hide();
    });
  }

  onFileSelected(event) {
    if(event.target.files && event.target.files.length) {
      const file: File = event.target.files[0]; 

      this.uploadForm.patchValue({
        arquivo: event.target.files[0]
      });

      this.uploadForm.get('arquivo').markAsDirty();
      this.uploadForm.get('arquivo').updateValueAndValidity();
    }
  }

  onTipoFaseChanged(tipoFase: TipoFase) {
    const descricaoFaseControl = this.uploadForm.get('descricaoFase');
   
    if(tipoFase) {
      descricaoFaseControl.setValidators([Validators.required, Validators.maxLength(6000)]);
    } else {
      descricaoFaseControl.clearValidators();
    }

    descricaoFaseControl.updateValueAndValidity();
  }

  handleClose() {
    this.bsModalRef.hide();
  }

}
