import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DocumentoService } from 'app/shared/services/documento.service';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Assinatura } from 'app/shared/types/models.type';

@Component({
  selector: 'app-modal-assinatura',
  templateUrl: './modal-assinatura.component.html',
  styleUrls: ['./modal-assinatura.component.css']
})
export class ModalAssinaturaComponent implements OnInit {

  codigoArquivo: number;
  errorMessage: string;
  loading = false;

  assinaturaForm: FormGroup;
  responseAssinatura: Assinatura;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private documentoService: DocumentoService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.assinaturaForm = this.fb.group({
      'usuario': ['', [ Validators.required, Validators.maxLength(150) ]],
      'senha': ['', [ Validators.required, Validators.maxLength(150) ]]
    });
  }

  submit() {
    for(const i in this.assinaturaForm.controls) {
      this.assinaturaForm.controls[i].markAsDirty();
      this.assinaturaForm.controls[i].updateValueAndValidity();
    }

    if(this.assinaturaForm.valid) {
      this.loading = true;
      this.errorMessage = null;

      this.documentoService.sign({ 
        ...this.assinaturaForm.value,
        codigoArquivo: this.codigoArquivo
      }).pipe(
        catchError(err => {
          this.errorMessage = err.error.message;
          return throwError(err);
        }),
        finalize(() => this.loading = false)
      ).subscribe(res => {
        this.responseAssinatura = res;
        this.bsModalRef.hide();
      });
    }
  }

  handleClose() {
    this.bsModalRef.hide();
  }

}
