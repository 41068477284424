import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProtocoloService } from 'app/shared/services/protocolo.service';
import { ParteService } from 'app/shared/services/parte.service';
import { forkJoin, Observable } from 'rxjs';
import { ProtocoloInfo } from 'app/shared/types/protocolo-info';
import { ParteProcesso } from 'app/shared/types/parte-processo';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.css']
})
export class ModalInfoComponent implements OnInit {

  @Input() numeroDocumento: string;

  info$: Observable<{ info: ProtocoloInfo, partes: ParteProcesso[] }>;

  constructor(
    private bsModalRef: BsModalRef,
    private protocoloService: ProtocoloService,
    private parteProcessoService: ParteService
  ) {}

  ngOnInit() {
    this.info$ = forkJoin({
      info: this.protocoloService.getInfoProtocolo(this.numeroDocumento),
      partes: this.parteProcessoService.listar(this.numeroDocumento)
    });
  }

  handleClose() {
    this.bsModalRef.hide();
  }

}
