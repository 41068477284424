import { Injectable } from "@angular/core";
import { HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";
import { AnexoResponse, Assinatura, Minuta, SignRequest } from "../types/models.type";
import { map, take } from "rxjs/operators";


@Injectable({
    providedIn: "root"
})
export class MinutaService {

    private serviceUrl = "api/v1/documento";

    constructor(
        private http: HttpClient
    ) {}

    
    criar(numeroDocumento, minuta: Minuta) : Observable<Minuta> {
        return this.http.post<Minuta>(`${this.serviceUrl}/${numeroDocumento}/minuta`, minuta)
    }

    listar(numeroDocumento) : Observable<Minuta[]> {
        return this.http.get<Minuta[]>(`${this.serviceUrl}/${numeroDocumento}/minuta`)
    }

    buscar(numeroDocumento, id:number) : Observable<Minuta> {
        return this.http.get<Minuta>(`${this.serviceUrl}/${numeroDocumento}/minuta/${id}`)
    }

    atualizar(numeroDocumento, minuta: Minuta) : Observable<Minuta> {
        return this.http.put<Minuta>(`${this.serviceUrl}/${numeroDocumento}/minuta/${minuta.id}`, minuta)
    }

    listarMembros(numeroDocumento, id:number) : Observable<any[]> {
        return this.http.get<any[]>(`${this.serviceUrl}/${numeroDocumento}/minuta/${id}/membro`)
    }

    desvincularMembro(numeroDocumento, idMinuta:number, idMembro:number) : Observable<any> {
        return this.http.patch<any>(`${this.serviceUrl}/${numeroDocumento}/minuta/${idMinuta}/membro/${idMembro}/desvincular`,{})
        .pipe(take(1));
    }

    vincularMembro(numeroDocumento, idMinuta:number, idMembro:number) : Observable<any> {
        return this.http.patch<any>(`${this.serviceUrl}/${numeroDocumento}/minuta/${idMinuta}/membro/${idMembro}/vincular`,{})
        .pipe(take(1));
    }

    publicar(numeroDocumento, idMinuta:number, formData: FormData): Observable<AnexoResponse> {
        return this.http.post<AnexoResponse[]>(`${this.serviceUrl}/${numeroDocumento}/minuta/${idMinuta}/publicar`, formData)
        .pipe(
            take(1),
            map(anexos => anexos[0])
        );
    }

    sign(numeroDocumento, idMinuta:number, signRequest: SignRequest): Observable<Assinatura> {
        return this.http.post<Assinatura>(`${this.serviceUrl}/${numeroDocumento}/minuta/${idMinuta}/assinar`, signRequest)
        .pipe(take(1));
    }

    excluir(numeroDocumento, idMinuta:number) : Observable<void> {
        return this.http.delete<void>(`${this.serviceUrl}/${numeroDocumento}/minuta/${idMinuta}`)
    }
}