import { Component, Input } from '@angular/core';
import { AuthService } from 'app/shared/services/auth.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-notificacao',
  templateUrl: './modal-notificacao.component.html'
})
export class ModalNotificacaoComponent {

  @Input() message: string;

  constructor(private bsModalRef: BsModalRef) { }

  handleClose() {
    this.bsModalRef.hide();
  }
}
