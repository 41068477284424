import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { CertificadoDigital } from "../types/models.type";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class CertificadoDigitalService {

    private serviceUrl = "api/v1/certificado-digital";

    constructor(
        private http: HttpClient
    ) {}

    fetchEntities(): Observable<CertificadoDigital[]> {
        return this.http.get<CertificadoDigital[]>(`${this.serviceUrl}`);
    }

    upload(formData: FormData): Observable<void> {
        return this.http.post<void>(`${this.serviceUrl}/upload`, formData)
        .pipe(take(1));
    }

}