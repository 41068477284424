import { combineLatest, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { PAGINACAO_PAGE_SIZE } from "../constantes";
import { Page } from "./page.type";

export interface IPagination {
    pageSize: number;
    pageIndex: number;
}

export class Pagination implements IPagination{
    pageSize: number;
    pageIndex: number;

    Pagination(pageSize = PAGINACAO_PAGE_SIZE, pageIndex = 1){
        this.pageSize = pageSize;
        this.pageIndex = pageIndex;
    }

    static paginar(pageChange:Observable<IPagination>, search: Observable<{ [key: string]: string }>, func): Observable<any>{
        return combineLatest([pageChange, search])
            .pipe(
              map(([pageChange, search]) => this.geraParametroPaginacao([pageChange, search])),
              switchMap(param => func(param))
            );
    }

    private static geraParametroPaginacao([pageChange, search]):{ [key: string]: string } {
        let param = search;
        param['page'] = pageChange.pageIndex? `${pageChange.pageIndex-1}` : `${-1}`;
        param['size'] = pageChange.pageSize? `${pageChange.pageSize}` : `${PAGINACAO_PAGE_SIZE}`;
        return param;
    }
}