import { Injectable, Injector } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.injector.get(AuthService);

    return next.handle(request).pipe(
      catchError(err => {
        if (err instanceof HttpErrorResponse) {
          switch(err.status) {
            case 401:
              authService.logout();
              break;
            case 403:
              this.router.navigate(['/pages/403']);
              break;
            case 404:
              this.router.navigate(['/pages/404']);
              break;
            case 504:
              this.router.navigate(['/pages/manutencao']);
              break;
            default:
              return throwError(err);
          }
        }

        return throwError(err);
      })
    );
  }

}