import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { catchError, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Assinatura } from 'app/shared/types/models.type';
import { MinutaService } from 'app/shared/services/minuta.service';

@Component({
  selector: 'app-modal-preassinatura',
  templateUrl: './modal-preassinatura.component.html',
  styleUrls: ['./modal-preassinatura.component.css']
})
export class ModalPreAssinaturaComponent implements OnInit {

  codigoArquivo: number;
  numeroDocumento;
  errorMessage: string;
  loading = false;

  assinaturaForm: FormGroup;
  responseAssinatura: Assinatura|any;

  constructor(
    private fb: FormBuilder,
    private bsModalRef: BsModalRef,
    private minutaService: MinutaService
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.assinaturaForm = this.fb.group({
      'usuario': ['', [ Validators.required, Validators.maxLength(150) ]],
      'senha': ['', [ Validators.required, Validators.maxLength(150) ]]
    });
  }

  submit() {
    for(const i in this.assinaturaForm.controls) {
      this.assinaturaForm.controls[i].markAsDirty();
      this.assinaturaForm.controls[i].updateValueAndValidity();
    }

    if(this.assinaturaForm.valid) {
      this.loading = true;
      this.errorMessage = null;

      this.minutaService.sign(this.numeroDocumento, this.codigoArquivo,{ 
        ...this.assinaturaForm.value,
        codigoArquivo: this.codigoArquivo
      }).pipe(
        catchError(err => {
          this.errorMessage = err.error.message;
          return throwError(err);
        }),
        finalize(() => this.loading = false)
      ).subscribe(res => {
        this.responseAssinatura = {codigoArquivo: this.codigoArquivo};
        this.bsModalRef.hide();
      });
    }
  }

  handleClose() {
    this.bsModalRef.hide();
  }

}
